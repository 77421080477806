<template>
  <div class="container mt-24 md:mt-6 md:min-h-screen">
    <div class="md:grid grid-cols-12 linear-gradient-gray px-3 md:px-0 pt-12 pb-12 md:pt-48 md:pb-44 rounded-[10px]">
      <div class="col-start-3 col-span-8">
        <div v-if="successShow">
          <AtomTitle class="mb-10 md:mb-16" animation="rotation"> Сообщение <br />отправлено!</AtomTitle>
          <AtomTitle tag="h3" class="max-w-[500px]" class-name="-md:text-base " animation="rotation">
            В&nbsp;ближайшее время наш менеджер свяжется с&nbsp;вами.
          </AtomTitle>
        </div>
        <div v-else>
          <AtomTitle class="mb-10 md:mb-16" animation="rotation">
            УЗНАТЬ
            <br />БОЛЬШЕ
          </AtomTitle>
          <form class="form-two-col" @submit.prevent="send">
            <div class="form-input">
              <MoleculeSelect
                v-model="payload.club"
                :error="submitted && error.club"
                :items="clubs"
                :initial-index="initialClub"
                placeholder="Выберите клуб"
                class="item-input mb-2"
                @input="validate('club')"
              />
              <MoleculeInputPhone
                :error="submitted && error.phone"
                :value="payload.phone"
                label="Телефон*"
                type="tel"
                theme="white"
                @onInput="onInputPhone"
                @blur="onInputPhone"
              />
              <MoleculeInputText
                v-model="payload.first_name"
                :error="submitted && error.first_name"
                label="Имя*"
                class="item-input mb-2"
                type="text"
                @blur="validate('first_name')"
                @input="validate('first_name')"
              />
              <MoleculeInputText
                v-model="payload.last_name"
                :error="submitted && error.last_name"
                label="Фамилия*"
                class="item-input mb-2"
                type="text"
                @blur="validate('last_name')"
                @input="validate('last_name')"
              />
              <MoleculeInputText
                v-model="payload.email"
                :error="submitted && error.email"
                label="Email*"
                class="item-input mb-2"
                type="email"
                @blur="validate('email')"
                @input="validate('email')"
              />
            </div>
            <div class="controls flex flex-col mt-10 md:gap-y-14 md:mt-14">
              <MoleculeRadio
                v-model="radio"
                class="md:mt-3"
                type="radio"
                :checked="radio"
                :error="submitted && error.radio"
                label="Я соглашаюсь с условиями обработки <br /> персональных данных"
                @input="error.radio = !radio"
              />
              <AtomButton :disabled="submitting" tag="button" type="submit" class="w-full md:w-auto mt-10 md:mt-0">
                ОТПРАВИТЬ
              </AtomButton>
            </div>
            <div v-if="errorShow" class="text-red text-center text-sm mt-5">Произошла ошибка. Попробуйте позже</div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoleculeSelect from '../../molecules/forms/MoleculeSelect'
import AtomButton from '../../atoms/AtomButton'
import AtomTitle from '../../atoms/builder/AtomTitle'
import MoleculeInputText from '../../molecules/forms/MoleculeInputText'
import MoleculeInputPhone from '../../molecules/forms/MoleculeInputPhone'
import MoleculeRadio from '../../molecules/forms/MoleculeRadio'

export default {
  name: 'TemplateForms',
  components: {
    AtomTitle,
    AtomButton,
    MoleculeInputText,
    MoleculeInputPhone,
    MoleculeRadio,
    MoleculeSelect,
  },
  props: ['context'],
  builder: {
    component: 'form-learn-more',
  },
  data: () => {
    return {
      successShow: false,
      errorShow: false,
      submitted: false,
      submitting: false,
      radio: false,
      payload: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        club: null,
      },
      error: {
        first_name: null,
        last_name: null,
        phone: null,
        radio: null,
        email: null,
        club: null,
      },
    }
  },
  computed: {
    clubs() {
      return (this.$store.getters['modules/content/getClubsAsListForm'] || []).filter(c => {
        return this.context?.cityId ? this.context.cityId == c.cityId : true
      })
    },
    initialClub() {
      const index = this.clubs.findIndex(club => {
        return club.slug == this.context?.club?.slug
      })
      return index === -1 ? null : index
    },
  },
  methods: {
    getUtmList() {
      const utmList = {}

      // eslint-disable-next-line camelcase
      const utm_term = this.$cookies?.get('utm_term') || ''
      // eslint-disable-next-line camelcase
      const utm_source = this.$cookies?.get('utm_source') || ''
      // eslint-disable-next-line camelcase
      const utm_medium = this.$cookies?.get('utm_medium') || ''
      // eslint-disable-next-line camelcase
      const utm_campaign = this.$cookies?.get('utm_campaign') || ''
      // eslint-disable-next-line camelcase
      const utm_content = this.$cookies?.get('utm_content') || ''

      // eslint-disable-next-line camelcase
      if (utm_term) {
        // eslint-disable-next-line camelcase
        utmList.utm_term = utm_term
      }
      // eslint-disable-next-line camelcase
      if (utm_source) {
        // eslint-disable-next-line camelcase
        utmList.utm_source = utm_source
      }
      // eslint-disable-next-line camelcase
      if (utm_medium) {
        // eslint-disable-next-line camelcase
        utmList.utm_medium = utm_medium
      }
      // eslint-disable-next-line camelcase
      if (utm_campaign) {
        // eslint-disable-next-line camelcase
        utmList.utm_campaign = utm_campaign
      }
      // eslint-disable-next-line camelcase
      if (utm_content) {
        // eslint-disable-next-line camelcase
        utmList.utm_content = utm_content
      }

      return utmList
    },

    send() {
      this.errorShow = false
      this.submitted = true
      if (this.validateForm()) {
        const phone = `+${this.payload.phone.replace(/[^0-9]/g, '')}`
        let visitorUid = 'unknown'
        try {
          visitorUid = window.AMOPIXEL.getVisitorUid()
        } catch (e) {}
        this.submitting = true

        const utmList = this.getUtmList()
        const payload = {
          ...this.payload,
          phone,
          club: this.payload.club.slug,
          visitor_uid: visitorUid,
          ...utmList,
        }

        const sessionId = window?.ct('calltracking_params', 'b4d68ca9')?.sessionId || 0

        if (sessionId) {
          payload.sessionId = sessionId
        }

        this.$axios
          .post('/api/forms/learn', {
            data: payload,
          })
          .then(() => {
            this.successShow = true
            this.sendReachGoal('success-form-learn', { api: 'forms/learn' })
          })
          .catch(() => {
            this.errorShow = true
          })
          .finally(() => {
            this.submitting = false
          })
      }
    },
    validateForm() {
      Object.keys(this.payload).forEach(key => {
        this.validate(key)
      })
      this.error.radio = !this.radio
      return !Object.values(this.error).some(Boolean)
    },
    validate(key) {
      const textIsInvalid = v => !(v || '').trim()
      const schema = {
        first_name: textIsInvalid,
        last_name: textIsInvalid,
        phone: v => !v || v.length !== 16,
        email: textIsInvalid,
        club: v => !v,
      }
      if (key in this.error && schema[key]) {
        const value = this.payload[key]
        this.error[key] = schema[key](value)
      }
    },
    onInputPhone(value, objValidPhone) {
      this.payload.phone = value

      if (!this.payload.phone || !objValidPhone.isValid) this.error.phone = !objValidPhone.isValid
      else this.error.phone = false
    },
  },
}
</script>

<style lang="postcss" scoped>
@media (min-width: 960px) {
  .form-two-col {
    .form-input {
      display: grid;
      gap: 20px;
      grid-auto-flow: row dense;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        '. .'
        '. .'
        'a1 a1';
      ::v-deep .item-input:last-child {
        grid-area: a1;
      }
    }
    .controls {
      @apply md:grid grid-cols-2 gap-5 mt-8;
    }
  }
}
</style>
